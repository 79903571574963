import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store';

import CommonLanding from '../views/commons/Landing.vue'
import CommonApp from '../views/commons/App.vue'
import LoginIndex from '../views/onboarding/login/Index.vue'
import RegisterIndex from '../views/onboarding/register/Index.vue'
import { AccountsServices } from '@/services/Accounts';


const common: Array<RouteRecordRaw> = [
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/commons/NotFound.vue'),
    meta: { title: 'Sorry, we have nothing to show you here', type: 'public' } 
  }
]

const landing: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: CommonLanding,
    meta: { title: 'Home', type: 'public' },
    children: [
      { path: '/terms-of-service', component: () => import('@/views/pages/TermsOfService.vue'), meta: { title: 'Terms Of Service', type: 'public' } },
      { path: '/privacy-policy', component: () => import('@/views/pages/PrivacyPolicy.vue'), meta: { title: 'Privacy Policy', type: 'public' } }
    ]
  },
];

const app: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: CommonApp,
    children: [
      { path: '', component: () => import('@/views/dashboard/Index.vue'), meta: { title: 'Dashboard', type: 'auth-needed' } }
    ]
  },
  {
    path: '/invitation/:process*',
    component: CommonApp,
    meta: { title: 'Invitation', type: 'non-auth' },
  },
  {
    path: '/shipments',
    component: CommonApp,
    meta: { title: 'Shipments', type: 'auth-needed' },
    children: [
      { path: ':process*', component: () => import('@/views/shipments/Index.vue'), meta: { account_check: true } },
      { path: 'new', meta: { title: 'Create new shipment', badge: 'New' },  component: () => import('@/views/shipments/Editor.vue') },
      { path: ':id', meta: { title: 'Edit shipment' },  component: () => import('@/views/shipments/Editor.vue') }
    ]
  },
  {
    path: '/quotes',
    component: CommonApp,
    meta: { title: 'Quotes', type: 'auth-needed' },
    children: [
      { path: ':process*', component: () => import('@/views/quotes/Index.vue'), meta: { account_check: true } },
      { path: 'new', meta: { title: 'Create new request' },  component: () => import('@/views/quotes/share/NewRequest.vue') },
      { path: 'response/:id', meta: { title: 'Submit Your Quote' },  component: () => import('@/views/quotes/Response.vue') },
      { path: 'compare/:id', meta: { title: 'Quote Compare List' },  component: () => import('@/views/quotes/Compare.vue') }
    ]
  },
  {
    path: '/connections',
    component: CommonApp,
    meta: { title: 'Connections', type: 'auth-needed' },
    children: [
      { path: ':id?', component: () => import('@/views/connection/Index.vue') }
    ]
  },
  {
    path: '/documents',
    component: CommonApp,
    meta: { title: 'Documents', type: 'auth-needed' },
    children: [
      { path: '', component: () => import('@/views/documents/Index.vue') }
    ]
  },
  {
    path: '/support',
    component: CommonApp,
    meta: { title: 'Support', type: 'auth-needed' },
    children: [
        {path: '', component: () => import('@/views/support/support/Index.vue')},
        {path: ':topic', component: () => import('@/views/support/topic/Index.vue'), meta: { type: 'auth-needed' } },
        {path: 'detail', component: () => import('@/views/support/topic/Detail.vue'), meta: { type: 'auth-needed' } }
    ]
  },
  {
    path: '/settings',
    component: CommonApp,
    children: [
      { 
        path: '',
        component: () => import('@/views/settings/Index.vue'),
        redirect: '/settings/profile',
        meta: { title: 'Settings', type: 'auth-needed' },
        children: [
          { path: 'profile', component: () => import('@/views/settings/profile/Index.vue'), meta: { title: 'Profile', type: 'auth-needed' } },
          { path: 'companyinfo', component: () => import('@/views/settings/company/Index.vue'), meta: { title: 'Company', type: 'auth-needed' } },
          { path: 'users', component: () => import('@/views/settings/users/Index.vue'), meta: { title: 'Users', type: 'auth-needed' } },
          { path: 'notifications', component: () => import('@/views/settings/notifications/Index.vue'), meta: { title: 'Notifications', type: 'auth-needed' } },
        ]
      }
    ]
  },
  {
    path: '/login',
    component: LoginIndex,
    meta: { title: 'Login', type: 'non-auth' },
    children: [{ path: '', component: () => import('@/views/onboarding/login/Login.vue') }],
  },
  {
    path: '/forgot-password',
    component: LoginIndex,
    meta: { title: 'Forgot Password', type: 'non-auth' },
    children: [{ path: '', component: () => import('@/views/onboarding/login/Forgot.vue') }],
  },
  {
    path: '/reset-password/:data',
    component: LoginIndex,
    meta: { title: 'Reset Password', type: 'non-auth' },
    children: [{ path: '', component: () => import('@/views/onboarding/login/Reset.vue') }],
  },
  {
    path: '/register',
    component: RegisterIndex,
    children: [
      { path: '', component: () => import('@/views/onboarding/register/Register.vue'), meta: { title: 'Register', type: 'non-auth' } },
      { path: 'verify', component: () => import('@/views/onboarding/register/Verify.vue'), meta: { title: 'Verify', type: 'non-auth' } },
      { path: 'profile', component: () => import('@/views/onboarding/register/Profile.vue'), meta: { title: 'Register (Profile)', type: 'auth-needed' } },
      { path: 'company', component: () => import('@/views/onboarding/register/Company.vue'), meta: { title: 'Register (Company)', type: 'auth-needed' } }
    ]
  },
];

const subdomain = window.location.host.split('.')[0];
const routes = common.concat(subdomain === 'app' ? app : landing);  
const router = createRouter({ history: createWebHistory(), routes })

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title ? to.meta.title + ' - ' : ''} Logictics`;
  if (to.meta.type === 'auth-needed' && !store.state.authorization) {
    store.dispatch('setRedirectUrl', to.fullPath);
    if (to.meta?.account_check) {
      const accountServices = AccountsServices.getInstance();
      const check = await accountServices.check(to.hash.replace('#!', '')).catch(error => next('/login') );
      const route = check['is_exists'] ? 'login' : 'register';
      return next(`/${route}${to.hash}`);
    }
    else {
      return next('/login');
    }
  }
  if (to.meta.type === 'non-auth' && store.state.authorization) return next('/');

  /* App Force Routes */
  if (subdomain == 'app' && store.state.authorization) {
    if (! store.state.user || ! store.state.companies) return next();
    if (to.path !== '/register/profile' && ! store.state.user.is_complete) return next('/register/profile');
    // if (store.state.user && store.state.user.is_complete) {
    //   if (to.path !== '/register/company' && ! store.state.companies.length) return next('/register/company');
    //   if (store.state.companies && store.state.companies.length) {
    //     if (to.path.startsWith('/register')) return next('/');
    //   }
    // }
  }
  /* App Force Routes */

  next();
});

export default router
