import { renderSlot as _renderSlot, withKeys as _withKeys, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7275c76e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-containar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.status)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["overlay", { closing: _ctx.closing }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "content",
            onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"]))
          }, [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ], 32)
        ])
      ], 2))
    : _createCommentVNode("", true)
}