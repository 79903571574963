import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-861a5128"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    modules: _ctx.modules,
    pagination: _ctx.pagination,
    autoplay: _ctx.autoplay,
    loop: true,
    onSwiper: _ctx.onSwiper,
    onSlideChange: _ctx.onSlideChange
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slider, (item) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, {
          key: item.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (item.title)
                ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(item.title), 1))
                : _createCommentVNode("", true),
              (item.text)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(item.text), 1))
                : _createCommentVNode("", true),
              (item.image)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    src: item.image
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 2
        }, 1024))
      }), 128)),
      _createElementVNode("div", {
        class: _normalizeClass(["pagination", _ctx.pagerPosition])
      }, null, 2)
    ]),
    _: 1
  }, 8, ["modules", "pagination", "autoplay", "onSwiper", "onSlideChange"]))
}