import store from "@/store";
import ApiServices from "./Api";

export class VariableServices extends ApiServices {

  private static instance?: VariableServices;

  public static getInstance(): VariableServices {
    if (! this.instance) this.instance = new VariableServices();
    return this.instance;
  }

  public init = async () => {
    const response = await this.api.get(`/service/variables/init`);
    store.dispatch('setInit', response.data);
    return response;
  }
  public get = async (key: string, info: any) => {
    if (! store.state.init.length) await this.init();
    const check: any = store.state.init.find((item: any) => item.key == key);
    const variable: any = store.state.variables.find((item: any) => item.key == key);
    if (!info && variable && check.last_update == variable.time) return variable;
    const response = await this.api.get(`/service/variables/${key}?${new URLSearchParams(Object(info))}`);
    if(info) return response;
    let variables = store.state.variables;
    const newVariable = { key: key, data: response.data, time: check.last_update };
    if (variable) {
      variables = store.state.variables.map((item: any) => {
        if (item.key == key) return newVariable;
        return item;
      });
    }
    else {
      variables.push(newVariable);
    }
    store.dispatch('setVariables', variables);
    return response;
  };
  
  // public container_type = () => this.api.get(`/service/variables/container_type`);
  // public shipping_mode = () => this.api.get(`/service/variables/shipping_mode`);
  // public shipping_terms = () => this.api.get(`/service/variables/shipping_terms`);
  // public product_type = () => this.api.get(`/service/variables/product_type`);
  // public imo_class = () => this.api.get(`/service/variables/imo_class`);
  // public ship_types = () => this.api.get(`/service/variables/ship_types`);
  // public hs_codes = (parent, search, deep) => this.api.get(`/service/variables/hs_codes${parent || search ? '?':''}${parent ? 'parent='+parent : '' }${parent & search ? '&':''}${search ? 'search='+search : '' }${deep ? '&deep='+deep : '' }`);
    
  // public shipment_roles = () => this.api.get(`/service/variables/shipment_roles`);

}